exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bpg-cloudworks-index-tsx": () => import("./../../../src/pages/bpg-cloudworks/index.tsx" /* webpackChunkName: "component---src-pages-bpg-cloudworks-index-tsx" */),
  "component---src-pages-bpg-nxt-index-tsx": () => import("./../../../src/pages/bpg-nxt/index.tsx" /* webpackChunkName: "component---src-pages-bpg-nxt-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-teams-index-tsx": () => import("./../../../src/pages/teams/index.tsx" /* webpackChunkName: "component---src-pages-teams-index-tsx" */),
  "component---src-pages-work-with-us-index-tsx": () => import("./../../../src/pages/work-with-us/index.tsx" /* webpackChunkName: "component---src-pages-work-with-us-index-tsx" */),
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/article/Article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-case-study-case-study-tsx": () => import("./../../../src/templates/case-study/CaseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-case-study-tsx" */),
  "component---src-templates-team-member-team-member-tsx": () => import("./../../../src/templates/team-member/TeamMember.tsx" /* webpackChunkName: "component---src-templates-team-member-team-member-tsx" */)
}

